<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Nickname"
              v-model="filter.nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Owner</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Owner"
              v-model="filter.owner"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Leader</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Project Leader"
              v-model="filter.leader"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Contract Number"
              v-model="filter.contract_num"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="onFilter(filter)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="d-flex justify-content-end mb-5">
          <button
            class="btn btn-primary btn-md"
            @click="onReloadProject"
            ref="kt_load_project"
          >
            <i class="flaticon2-refresh"></i> Load Project
          </button>
        </div>

        <complete-table :data="currentProjectAll" :loading="loading">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Project Code</span>
              </th>
              <th style="min-width: 100px">Project Name</th>
              <th style="min-width: 100px">Nickname</th>
              <th style="min-width: 100px">Status</th>
              <th style="min-width: 100px">Owner</th>
              <th style="min-width: 100px">Contract Number</th>
              <th style="min-width: 150px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.nickname }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.status }}
                </span>
              </td>

              <td>
                <span class="text-muted font-weight-bold d-block">
                  {{ item.owner }}
                </span>
              </td>

              <td>
                <span class="text-muted font-weight-bold d-block">
                  {{ item.contract_no }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(item)"
                >
                  <i class="flaticon-close"></i>
                </a>
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toEditPage(item)"
                >
                  <i class="flaticon-edit"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large"
              >Are You Sure Want To Close This Project?</label
            >
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="onCloseProject"
            block
          >
            Yes
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_PROJECT_ALL,
  POST_CLOSE_PROJECT,
  POST_LOAD_PROJECT
} from "@/core/services/store/project.module";
import { mapGetters } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";


export default {
  name: "ordering",
  components: { CompleteTable },
  data() {
    return {
      filter: {
        nickname: null,
        leader: null,
        owner: null,
        contract_num: null
      },
      currentItem: null,
      options: {
        statuses: [
          { value: null, text: "Pilih Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ]
      },
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN"
          }
        ]
      },
      loadingActive: false,
      tableMainProject: true
    };
  },
  methods: {
    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    onCloseProject() {
      this.$store.dispatch(POST_CLOSE_PROJECT, { project_id: this.currentItem?.code })
        .then(() => {
          this.onFilter(this.filter);
          this.closeDeleteModal();
        })
    },

    onReloadProject() {
      const submitButton = this.$refs["kt_load_project"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store.dispatch(POST_LOAD_PROJECT);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 127377);
    },
    showDeleteModal(data) {
      this.currentItem = data;
      this.$refs["delete"].show();
    },

    toEditPage(data) {
      this.$router.push({
        name: "edit-detail-project",
        params: {
          id: data.code
        }
      });
    },

    onFilter(param) {
      const filter = {
        params: {
          owner: param.owner === null ? "" : param.owner,
          contract_num: param.contract_num === null ? "" : param.contract_num,
          leader: param.leader === null ? "" : param.leader,
          nickname: param.nickname === null ? "" : param.nickname
        }
      };

      this.$store
        .dispatch(GET_PROJECT_ALL, filter)
        .then(() => console.log("project all", this.currentProjectAll));
    }
  },
  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Project Detail" }]);
    this.onFilter(this.filter);

    this.loadingActive = true;
    this.tableMainProject = false;
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentMenus",
      "currentProjectAll",
      "currentLoadingProject"
    ]),
    loading() {
      return this.currentLoadingProject;
    }
  }
};
</script>

<style></style>
